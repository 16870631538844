import * as Yelp from './yelp';
import * as Gyg from './gyg';
import * as Bb from './bb';
import * as Viator from './viator';
import * as Rezdy from './rezdy';
import * as Videreo from './videreo';
import * as Toristy from './toristy';
import * as Victory from './victory';

export { Yelp, Gyg, Bb, Viator, Rezdy, Videreo, Toristy, Victory };
