export enum PROVIDER_NAME {
  YELP = 'Yelp',
  // GLX = 'Glx',
  GYG = 'Gyg',
  OPEN_TABLE = 'OpenTable',
  BOOK_BARBADOS = 'BookBarbados',
  VIATOR = 'Viator',
  GOCITY = 'GoCity',
  TORISTY = 'Toristy',
  VICTORY = 'Victory',
}
