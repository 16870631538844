// eslint-disable-next-line import/no-extraneous-dependencies
import { Providers } from '@tripian/model';
import axiosLib, { AxiosInstance } from 'axios';

class API {
  private axios: AxiosInstance;

  private proxyURL: string;

  private apiUrl: string;

  private apiKey: string;

  constructor(apiUrl: string, apiToken: string, proxyURL: string) {
    this.proxyURL = proxyURL;
    this.apiUrl = apiUrl;
    this.apiKey = apiToken;
    this.axios = axiosLib.create();
    this.axios.defaults.baseURL = `${this.proxyURL}?url=${apiUrl}`;
    // this.axios.defaults.headers.common['X-Api-Key'] = apiToken;
    this.axios.defaults.timeout = 60000;
  }

  getCityId = async (cityId: number): Promise<string> => {
    const url = btoa(`https://commonapi.tripian.com/toristy-destination/${cityId}`);
    return this.axios
      .get(`${this.proxyURL}?url=${url}`)
      .then((res) => {
        return res.data?.data?.cityId;
      })
      .catch((errorResponse) => {
        throw errorResponse.response.data.originalResponse.data.message || errorResponse.response.data.error;
      });
  };

  serviceList = async (cityid: number, pagenum: number = 1, maxperpage: number = 100): Promise<Providers.Toristy.Product[]> => {
    try {
      const destination = await this.getCityId(cityid);

      if (!destination) {
        return Promise.reject(new Error('Invalid city ID or city not found.')); // Hata fırlatmak yerine Promise.reject ile yakalanmasını sağlıyoruz
      }

      const url = btoa(`${this.apiUrl}/services?pagenum=${pagenum}&maxperpage=${maxperpage}&cityid=${destination}&apikey=${this.apiKey}`);

      return await this.axios
        .get<Providers.Toristy.ServiceResponse>(`${this.proxyURL}?url=${url}`)
        .then((response) => response.data.services)
        .catch((errorResponse) => {
          return Promise.reject(new Error(`Service fetch error: ${errorResponse?.message || 'Unknown error'}`));
        });
    } catch (error) {
      return Promise.reject(error); // Hata propagatesi için direkt return yerine Promise.reject kullanıyoruz
    }
  };

  // serviceList = async (cityid: number, pagenum: number = 1, maxperpage: number = 100): Promise<Providers.Toristy.Product[]> => {
  //   const destination = await this.getCityId(cityid);

  //   if (destination === undefined) return [];

  //   const url = btoa(`${this.apiUrl}/services?pagenum=${pagenum}&maxperpage=${maxperpage}&cityid=${destination}&apikey=${this.apiKey}`);

  //   return this.axios
  //     .get<Providers.Toristy.ServiceResponse>(`${this.proxyURL}?url=${url}`)
  //     .then((products) => {
  //       const { data } = products;
  //       return data.services;
  //     })
  //     .catch((errorResponse) => {
  //       // eslint-disable-next-line no-console
  //       console.error('toristy-serviceListError', errorResponse);
  //       throw errorResponse.message;
  //     });
  // };

  serviceDetails = async (serviceid: string): Promise<Providers.Toristy.ProductDetailsResponse> => {
    const url = btoa(`${this.apiUrl}/service/${serviceid}?apikey=${this.apiKey}`);

    return this.axios.get<Providers.Toristy.ProductDetailsResponse>(`${this.proxyURL}?url=${url}`).then((res) => res.data);
  };
}

export default API;
