import axiosLib, { AxiosInstance } from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Providers } from '@tripian/model';
import { handleHttpResponseErrorForViator } from './handle';
// import bookingQuestions from './bookingQuestions';

class API {
  private axios: AxiosInstance;

  private proxyURL: string;

  private apiUrl: string;

  private lang: string;

  // private apiUrl = `https://api.viator.com/partner`;
  // private apiUrl_TEST = `https://api.sandbox.viator.com/partner/`;
  // private apiKey = 'ecb944f4-396a-42ec-a89c-3e59ef671465';
  // private apiKey_TEST = '87f21f6f-6cb4-4ba3-ac0b-b763edc67165';

  private sandbox: boolean;

  constructor(apiUrl: string, apiKey: string, sandbox: boolean, proxyURL: string, lang: string) {
    this.proxyURL = proxyURL;
    this.sandbox = sandbox;
    this.apiUrl = apiUrl;
    this.lang = lang;
    this.axios = axiosLib.create();
    this.axios.defaults.baseURL = `${this.proxyURL}?url=${apiUrl}`;
    this.axios.defaults.headers.common['exp-api-key'] = apiKey;
    this.axios.defaults.timeout = 120000;
  }

  private getDestinationId = async (cityName: string): Promise<string> => {
    const url = btoa(`${this.apiUrl}/search/freetext`);

    return this.axios
      .post<any>(
        `${this.proxyURL}?url=${url}`,
        {
          searchTerm: cityName,
          searchTypes: [
            {
              searchType: 'DESTINATIONS',
              pagination: {
                start: 1,
                count: 10,
              },
            },
          ],
          currency: 'USD',
        },
        {
          headers: {
            accept: 'application/json;version=2.0',
            'Accept-Language': this.lang,
          },
        },
      )
      .then((res) => {
        let destinationId = '';
        const totalCount = res.data.destinations.totalCount as number;
        if (totalCount > 0) {
          destinationId = res.data.destinations.results[0].id;
        }
        return destinationId;
      })
      .catch((errorResponse) => handleHttpResponseErrorForViator(errorResponse));
  };

  products = async (cityName: string, startDate: string, endDate: string, limit = 50): Promise<Providers.Viator.Product[]> => {
    const url = btoa(`${this.apiUrl}/products/search`);

    const destination = await this.getDestinationId(cityName);
    if (destination === '') return [];

    return this.axios
      .post<{ products: Providers.Viator.Product[] }>(
        `${this.proxyURL}?url=${url}`,
        {
          filtering: {
            destination, // '562',
            /* NOTE:
            Filter Out Products:
            You can now use tag ID 367651 to filter out products in the Viator Partner API that are provided by suppliers who have not yet been verified by Viator under Article 30 of the DSA to ensure they are not sold to EU users. 
            */
            tags: [367651],
            // flags: ['LIKELY_TO_SELL_OUT', 'FREE_CANCELLATION'],
            lowestPrice: 5,
            highestPrice: 500,
            startDate, // '2023-05-30',
            endDate, // '2023-06-28',
            includeAutomaticTranslations: true,
            confirmationType: 'INSTANT',
            durationInMinutes: {
              from: 0,
              to: 360,
            },
            rating: {
              from: 3,
              to: 5,
            },
          },
          sorting: {
            sort: 'TRAVELER_RATING',
            order: 'DESCENDING',
          },
          pagination: {
            start: 1,
            count: limit,
          },
          currency: 'USD',
        },
        {
          headers: {
            accept: 'application/json;version=2.0',
            'Accept-Language': this.lang,
          },
        },
      )
      .then((res) => {
        return res.data.products ?? [];
      })
      .catch((errorResponse) => handleHttpResponseErrorForViator(errorResponse));
  };

  productInfo = async (productCode: string): Promise<Providers.Viator.ProductInfo> => {
    const url = btoa(`${this.apiUrl}/products/${productCode}`);

    return this.axios
      .get<Providers.Viator.ProductInfo>(`${this.proxyURL}?url=${url}`, {
        headers: {
          accept: 'application/json;version=2.0',
          'Accept-Language': this.lang,
        },
      })
      .then((res) => res.data)
      .catch((errorResponse) => handleHttpResponseErrorForViator(errorResponse));
  };

  productBookingQuestions = async (): Promise<Providers.Viator.BookingQuestions> => {
    const url = btoa(`${this.apiUrl}/products/booking-questions`);

    return this.axios
      .get<Providers.Viator.BookingQuestions>(`${this.proxyURL}?url=${url}`, {
        headers: {
          accept: 'application/json;version=2.0',
          'Accept-Language': this.lang,
        },
      })
      .then((res) => res.data)
      .catch((errorResponse) => {
        // eslint-disable-next-line no-console
        console.log('errorResponse', errorResponse);
        // handleHttpResponseErrorForViator(errorResponse);
        return { bookingQuestions: [] };
      });
  };

  availabilitySchedule = async (productCode: string): Promise<Providers.Viator.AvailabilitySchedule> => {
    const url = btoa(`${this.apiUrl}/availability/schedules/${productCode}`);

    return this.axios
      .get<Providers.Viator.AvailabilitySchedule>(`${this.proxyURL}?url=${url}`, {
        headers: {
          accept: 'application/json;version=2.0',
          'Accept-Language': this.lang,
        },
      })
      .then((res) => res.data)
      .catch((errorResponse) => handleHttpResponseErrorForViator(errorResponse));
  };

  tourData = async (productCode: string): Promise<Providers.Viator.TourData> => {
    const info = await this.productInfo(productCode);
    const availability = await this.availabilitySchedule(productCode);
    const questions = await this.productBookingQuestions();
    const filteredQuestions = info.bookingQuestions
      .map((questionId) => questions.bookingQuestions.find((question) => question.id === questionId))
      .filter((question): question is Providers.Viator.BookingQuestion => question !== undefined);

    return {
      info,
      availability,
      bookingQuestions: filteredQuestions.length > 0 ? filteredQuestions : [],
    };
  };

  // Payment API Implementation https://partnerresources.viator.com/travel-commerce/api-payments/#api

  // Locations
  locations = async (locations: string[]): Promise<Providers.Viator.LocationList> => {
    const url = btoa(`${this.apiUrl}/locations/bulk`);

    return this.axios
      .post<Providers.Viator.LocationList>(
        `${this.proxyURL}?url=${url}`,
        { locations },
        {
          headers: {
            accept: 'application/json;version=2.0',
            'Accept-Language': this.lang,
          },
        },
      )
      .then((res) => {
        return res.data;
      })
      .catch((errorResponse) => handleHttpResponseErrorForViator(errorResponse));
  };

  // Check Availability and Pricing
  availabilityPriceCheck = async (
    request: Providers.Viator.AvailabilityCheckRequest,
  ): Promise<Providers.Viator.AvailabilityCheck | Providers.Viator.AvailabilityCheckErrorResponse> => {
    const url = btoa(`${this.apiUrl}/availability/check`);

    return this.axios
      .post<Providers.Viator.AvailabilityCheck>(`${this.proxyURL}?url=${url}`, request, {
        headers: {
          accept: 'application/json;version=2.0',
          'Accept-Language': this.lang,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((errorResponse) => handleHttpResponseErrorForViator(errorResponse));
  };

  // Request a Booking Hold
  bookingCardHold = async (request: Providers.Viator.BookingCardHoldRequest): Promise<Providers.Viator.BookingCardHold> => {
    const url = btoa(`${this.apiUrl}/bookings/cart/hold`);

    return this.axios
      .post<Providers.Viator.BookingCardHold>(`${this.proxyURL}?url=${url}`, request, {
        headers: {
          accept: 'application/json;version=2.0',
          'Accept-Language': this.lang,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((errorResponse) => handleHttpResponseErrorForViator(errorResponse));
  };

  // Payment
  payment = async (sessionToken: string, request: Providers.Viator.PaymentRequest): Promise<Providers.Viator.Payment> => {
    const url = btoa(`${this.apiUrl}/v1/checkoutsessions/${sessionToken}/paymentaccounts`);
    const uniqueId = `${Date.now()}-${Math.random()
      .toString(36)
      .slice(2, 11)}`;

    return this.axios
      .post<Providers.Viator.Payment>(`${this.proxyURL}?url=${url}`, request, {
        headers: {
          accept: 'application/json;version=2.0',
          'Accept-Language': this.lang,
          'Content-Type': 'application/json',
          'x-trip-clientid': 'P00058532', // Affiliate id
          'x-trip-requestid': uniqueId,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((errorResponse) => handleHttpResponseErrorForViator(errorResponse));
  };

  // Confirm the Booking
  bookingConfirm = async (request: Providers.Viator.BookingConfirmRequest): Promise<Providers.Viator.BookingConfirm> => {
    const url = btoa(`${this.apiUrl}/bookings/cart/book`);

    return this.axios
      .post<Providers.Viator.BookingConfirm>(`${this.proxyURL}?url=${url}`, request, {
        headers: {
          accept: 'application/json;version=2.0',
          'Accept-Language': this.lang,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((errorResponse) => handleHttpResponseErrorForViator(errorResponse));
  };

  // Request a Booking Status
  bookingStatus = async ({ bookingRef }: { bookingRef: string }): Promise<Providers.Viator.BookingConfirmItem> => {
    const url = btoa(`${this.apiUrl}/bookings/status`);

    return this.axios
      .post<Providers.Viator.BookingConfirmItem>(
        `${this.proxyURL}?url=${url}`,
        { bookingRef },
        {
          headers: {
            accept: 'application/json;version=2.0',
            'Accept-Language': this.lang,
          },
        },
      )
      .then((res) => {
        return res.data;
      })
      .catch((errorResponse) => handleHttpResponseErrorForViator(errorResponse));
  };

  // Refund Details
  bookingRefundDetails = async (bookingRef: string): Promise<Providers.Viator.BookingRefund> => {
    const url = btoa(`${this.apiUrl}/bookings/${bookingRef}/cancel-quote`);

    return this.axios
      .get<Providers.Viator.BookingRefund>(`${this.proxyURL}?url=${url}`, {
        headers: {
          accept: 'application/json;version=2.0',
          'Accept-Language': this.lang,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((errorResponse) => handleHttpResponseErrorForViator(errorResponse));
  };

  // Cancel the Booking
  bookingCancel = async (bookingRef: string): Promise<Providers.Viator.BookingCancel> => {
    const url = btoa(`${this.apiUrl}/bookings/${bookingRef}/cancel`);

    return this.axios
      .post<Providers.Viator.BookingCancel>(
        `${this.proxyURL}?url=${url}`,
        { reasonCode: 'Customer_Service.Supplier_no_show' },
        {
          headers: {
            accept: 'application/json;version=2.0',
            'Accept-Language': this.lang,
          },
        },
      )
      .then((res) => {
        return res.data;
      })
      .catch((errorResponse) => handleHttpResponseErrorForViator(errorResponse));
  };

  // Exchange Rates
  exchangeRates = async (sourceCurrencies: string[], targetCurrencies: string[]): Promise<Providers.Viator.ExchangeRates[]> => {
    const url = btoa(`${this.apiUrl}/exchange-rates`);

    return this.axios
      .post<Providers.Viator.ExchangeRates[]>(
        `${this.proxyURL}?url=${url}`,
        { sourceCurrencies, targetCurrencies },
        {
          headers: {
            accept: 'application/json;version=2.0',
          },
        },
      )
      .then((res) => {
        return res.data;
      })
      .catch((errorResponse) => handleHttpResponseErrorForViator(errorResponse));
  };
}

export default API;
