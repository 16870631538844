export enum PROVIDER_ID {
  VIATOR = 1,
  YELP = 2,
  GUESTLOGIX = 3,
  GYG = 4,
  OPEN_TABLE = 5,
  BOOK_BARBADOS = 6,
  JUNIPER = 7,
  REZDY = 8,
  UBER = 9,
  TRAVELIFY = 10,
  GOCITY = 11,
  TORISTY = 12,
  VICTORY = 13,
}

/* 
Client Name Vacavia
API Application ID  154
API Private Key 82202011-0EC4-4DA4-9D7C-1A0457F85973
API Endpoint Prefix https://api.travelify.io/
Selection Redirect Url  https://www.vacavia.co.uk/results#/ticketAndAttraction/searchSession= 

{
  id: 8,
  name: "Travelify",
  apiUrl: "https://api.travelify.io/",
  apiKey: "100:4D1BEDF7-F67A-4299-B544-F35521277F59",
  prod: false,
  clientId: "https://traveldemo.agendas.group/results#/ticketAndAttraction/searchSession="
},
{
  id: 8,
  name: "Travelify",
  apiUrl: "https://api.travelify.io/",
  apiKey: "154:82202011-0EC4-4DA4-9D7C-1A0457F85973",
  prod: true,
  clientId: "https://www.vacavia.co.uk/results#/ticketAndAttraction/searchSession="
}
*/
