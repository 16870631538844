/* eslint-disable import/no-extraneous-dependencies */
import Model from '@tripian/model';
import data from './data';

const dataClear = () => {
  // Reset initialization and error-related flags
  data.initialised = false;
  data.hasError = false;
  data.errors = [];
  data.logs = [];

  // Reset optional configList
  data.configList = undefined;

  // Reset all data structures to empty or undefined
  data.cities = {} as Record<Model.LangCodeKey, Model.City[]>;
  data.citiesInfo = {} as Record<Model.LangCodeKey, Model.CityInfo[]>;
  data.citiesEvents = {} as Record<Model.LangCodeKey, { cityId: number; events: Model.CityEvent[] }[]>;
  data.feedbacks = {} as Record<Model.LangCodeKey, Model.Feedbacks>;
  data.userFeedbacks = {} as Record<Model.LangCodeKey, Model.UserFeedback[]>;
  data.poiCategoryResponse = {} as Record<Model.LangCodeKey, Model.PoiCategoryResponse>;
  data.pois = {} as Record<Model.LangCodeKey, { showOffersOnly: number; pois: Model.Poi[] }[]>;
  data.questions = {} as Record<Model.LangCodeKey, Model.Question[]>;
  data.questionsTrip = {} as Record<Model.LangCodeKey, Model.Question[]>;
  data.questionsProfile = {} as Record<Model.LangCodeKey, Model.Question[]>;
  data.questionsCompanion = {} as Record<Model.LangCodeKey, Model.Question[]>;
  data.topTens = {} as Record<Model.LangCodeKey, { cityId: number; topTen: Model.TopTen[] }[]>;

  // Reset user data
  data.user = undefined;

  // Reset user-related records
  data.favorites = {} as Record<Model.LangCodeKey, { cityId: number; favoritePois: Model.Favorite[] }[]>;
  data.companions = {} as Record<Model.LangCodeKey, Model.Companion[]>;
  data.reservations = {} as Record<Model.LangCodeKey, Model.UserReservation[]>;
  data.userReactions = {} as Record<Model.LangCodeKey, { tripHash: string; userReactions: Model.UserReaction[] }[]>;

  // Reset trip references
  data.tripRefs = undefined;
  data.tripSavedRefs = undefined;
  data.trips = {} as Record<Model.LangCodeKey, Model.Trip[]>;
  data.trip = {} as Record<Model.LangCodeKey, Model.Trip>;

  // Reset POI-related data
  data.poisAll = {} as Record<
    Model.LangCodeKey,
    { cityId: number; poiCategories: number[]; showOffersOnly: number; pois: Model.DataPayload<Model.Poi[]> }[]
  >;

  // Reset autocomplete tags
  data.autoCompleteTags = {} as Record<Model.LangCodeKey, { id: number; name: string }[]>;
  data.autoCompleteTagsAll = {} as Record<Model.LangCodeKey, { cityId: number; poiCategories: number[]; tags: { id: number; name: string }[] }[]>;

  // You can uncomment or add any additional resets here if needed
  // data.notifications = undefined;
  // data.recommendations = undefined;
};

export default dataClear;
