/* eslint-disable import/no-extraneous-dependencies */
import { Providers } from '@tripian/model';
import axiosLib, { AxiosInstance } from 'axios';
import crypto from 'crypto';

class API {
  private static instance: API;

  private axios: AxiosInstance;

  private proxyURL: string;

  private apiUrl: string;

  private apiToken: string;

  private apiSecret: string;

  private static cache: Map<string, { data: any; timestamp: number }> = new Map();

  private readonly CACHE_DURATION = 5 * 60 * 1000; // 5 minute

  constructor(apiUrl: string, apiToken: string, proxyURL: string) {
    this.proxyURL = proxyURL;
    this.apiUrl = apiUrl;
    this.apiToken = apiToken;
    this.apiSecret = 'pEg7CJbcDFB90gK9+jTubzM24RCO1cpcpFGcU710';
    this.axios = axiosLib.create();
    this.axios.defaults.timeout = 60000;
  }

  public static getInstance(apiUrl: string, apiToken: string, proxyURL: string): API {
    if (!API.instance) {
      API.instance = new API(apiUrl, apiToken, proxyURL);
    }
    return API.instance;
  }

  /**
   * Generates an HMAC-SHA256 signature for authentication.
   */

  private generateSignature(method: string, endpoint: string, params?: string, body?: string): string {
    let fullEndpoint = endpoint;

    if (method === 'GET') {
      fullEndpoint += '?';
      if (params) {
        const sortedParams = params
          .split('&')
          .sort()
          .join('&'); // Alfabetik sıraya göre sıralama
        fullEndpoint += sortedParams;
      }
    } else if (['POST', 'PUT', 'DELETE'].includes(method)) {
      fullEndpoint += '?';
    }

    const formattedBody = body ? JSON.stringify(JSON.parse(body)) : '';

    const stringToSign = `${method} ${new URL(this.apiUrl).host}${fullEndpoint}${formattedBody}`;

    return crypto
      .createHmac('sha256', this.apiSecret)
      .update(stringToSign)
      .digest('base64');
  }

  /**
   * Constructs headers for API requests.
   */
  private getHeaders(method: string, endpoint: string, params?: string, body?: string) {
    const signature = this.generateSignature(method, endpoint, params, body);
    return {
      'X-Signature': signature,
      'X-Token': this.apiToken,
      Accept: 'application/json',
    };
  }

  private static getCacheKey(endpoint: string, params?: string): string {
    return `${endpoint}${params ? `?${params}` : ''}`;
  }

  private getFromCache<T>(key: string): T | null {
    const cached = API.cache.get(key);
    if (!cached) {
      return null;
    }

    const now = Date.now();
    if (now - cached.timestamp > this.CACHE_DURATION) {
      API.cache.delete(key);
      return null;
    }

    return cached.data as T;
  }

  private static setCache(key: string, data: any): void {
    API.cache.set(key, { data, timestamp: Date.now() });
  }

  /**
   * Fetches events based on latitude, longitude, and optional date filters.
   */
  events = async (lat: number, lon: number, startDate?: string, endDate?: string): Promise<Providers.Victory.Event[]> => {
    const params = new URLSearchParams();
    params.append('lat', lat.toString());
    params.append('lon', lon.toString());
    // if (startDate) params.append('occurs_at.gte', startDate);
    // if (endDate) params.append('occurs_at.lt', endDate);

    if (startDate) params.append('occurs_at.gte', `${startDate}T00:00:00Z`);
    if (endDate) params.append('occurs_at.lt', `${endDate}T23:59:59Z`);

    const queryString = params.toString();
    const endpoint = '/v9/events';
    const cacheKey = API.getCacheKey(endpoint, queryString);

    const cached = this.getFromCache<Providers.Victory.Event[]>(cacheKey);
    if (cached) return cached;

    const response = await this.axios.get<Providers.Victory.EventsResponse>(
      `${this.proxyURL}?url=${Buffer.from(`${this.apiUrl}${endpoint}?${queryString}`).toString('base64')}`,
      {
        headers: this.getHeaders('GET', endpoint, queryString),
      },
    );

    API.setCache(cacheKey, response.data.events);
    return response.data.events;
  };

  /**
   * Fetches event details by event ID.
   */
  eventDetails = async (eventid: string): Promise<Providers.Victory.EventDetail> => {
    const endpoint = `/v9/events/${eventid}`;
    const cacheKey = API.getCacheKey(endpoint);

    const cached = this.getFromCache<Providers.Victory.EventDetail>(cacheKey);
    if (cached) return cached;

    const response = await this.axios.get<Providers.Victory.EventDetail>(
      `${this.proxyURL}?url=${Buffer.from(`${this.apiUrl}${endpoint}`).toString('base64')}`,
      {
        headers: this.getHeaders('GET', endpoint),
      },
    );

    API.setCache(cacheKey, response.data);
    return response.data;
  };

  /**
   * Fetches venue details by venue ID.
   */
  venue = async (venueid: string): Promise<Providers.Victory.Venue> => {
    const endpoint = `/v9/venues/${venueid}`;
    const cacheKey = API.getCacheKey(endpoint);

    const cached = this.getFromCache<Providers.Victory.Venue>(cacheKey);
    if (cached) return cached;

    const response = await this.axios.get<Providers.Victory.Venue>(
      `${this.proxyURL}?url=${Buffer.from(`${this.apiUrl}${endpoint}`).toString('base64')}`,
      {
        headers: this.getHeaders('GET', endpoint),
      },
    );

    API.setCache(cacheKey, response.data);
    return response.data;
  };

  /**
   * Fetches list of ticket groups by event ID.
   */
  listTicketGroups = async (eventid: string): Promise<Providers.Victory.TicketResponse> => {
    const endpoint = '/v9/listings';
    const params = `event_id=${eventid}`;
    const cacheKey = API.getCacheKey(endpoint, params);

    const cached = this.getFromCache<Providers.Victory.TicketResponse>(cacheKey);
    if (cached) return cached;

    const response = await this.axios.get<Providers.Victory.TicketResponse>(
      `${this.proxyURL}?url=${Buffer.from(`${this.apiUrl}${endpoint}?${params}`).toString('base64')}`,
      {
        headers: this.getHeaders('GET', endpoint, params),
      },
    );

    API.setCache(cacheKey, response.data);
    return response.data;
  };

  /**
   * Fetches ticket info by ticket ID.
   */
  ticketInfo = async (ticketid: string): Promise<Providers.Victory.TicketGroup> => {
    const endpoint = `/v9/listings/${ticketid}`;
    const cacheKey = API.getCacheKey(endpoint);

    const cached = this.getFromCache<Providers.Victory.TicketGroup>(cacheKey);
    if (cached) return cached;

    const response = await this.axios.get<Providers.Victory.TicketGroup>(
      `${this.proxyURL}?url=${Buffer.from(`${this.apiUrl}${endpoint}?`).toString('base64')}`,
      {
        headers: this.getHeaders('GET', endpoint),
      },
    );

    API.setCache(cacheKey, response.data);
    return response.data;
  };

  clientsCreate = async (request: Providers.Victory.ClientsCreateRequest): Promise<Providers.Victory.ClientsData> => {
    const endpoint = '/v9/clients';
    const requestBody = JSON.stringify(request);

    return this.axios
      .post<Providers.Victory.ClientsData>(`${this.proxyURL}?url=${Buffer.from(`${this.apiUrl}${endpoint}`).toString('base64')}`, request, {
        headers: this.getHeaders('POST', endpoint, undefined, requestBody),
      })
      .then((res) => res.data)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('clientsCreate Error:', error.response?.data || error.message);
        throw error;
      });
  };

  order = async (request: Providers.Victory.ClientsCreateRequest): Promise<Providers.Victory.OrdersResponse> => {
    const endpoint = '/v10/orders';
    const requestBody = JSON.stringify(request);

    return this.axios
      .post<Providers.Victory.OrdersResponse>(`${this.proxyURL}?url=${Buffer.from(`${this.apiUrl}${endpoint}`).toString('base64')}`, request, {
        headers: this.getHeaders('POST', endpoint, undefined, requestBody),
      })
      .then((res) => res.data)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('order Error:', error.response?.data || error.message);
        throw error;
      });
  };
}

export default API;
